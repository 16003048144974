@import "@styles/theme", "@styles/mixins";

.container {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.nextTab {
  margin-left: 16px;
}

.videoWrapper {
  min-height: 26vw;
  width: 100%;
  position: relative;

  @include maxWidth {
    max-height: 560px;
    min-height: unset;
    height: 26vw;
  }

  iframe {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.slideContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  min-height: 350px;
  padding: 0 #{$section-horizontal-padding};

  @include desktop {
    min-height: 420px;
    padding: 0 #{$section-horizontal-padding-desktop};
  }
}

.controls {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
  min-width: 350px;
  z-index: 4;
  padding-bottom: 2.5rem;

  .arrows {
    padding-bottom: 4px;
    display: flex;

    button:first-of-type {
      margin-right: 8px;
    }
  }

  .bullets {
    height: 35px;
    margin-bottom: clamp(10px, 1vw, 28px);
  }

  .bullet {
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.active {
      @include bulletActive($primary);
    }

    span {
      transition: all $transition-time-quick $transition-easing;
      display: block;
      background-color: $text-secondary;
      width: clamp(10px, 0.6vw, 20px);
      height: clamp(10px, 0.6vw, 20px);
    }

    &:active {
      span {
        width: 24px !important;
      }
    }
    @include desktop {
      &:active,
      &:hover {
        @include bulletActive($primary);
        width: 24px !important;
      }
    }
  }
}

.slideImageContainer {
  aspect-ratio: 375 / 269 !important;
  @include desktop {
    aspect-ratio: 61 / 39 !important;
  }
  picture,
  img {
    width: 100% !important;
    height: 100% !important;
  }
}

.slideDetails {
  width: 40%;
  min-width: 350px;
  margin-left: toRem(48);
  flex-shrink: 0;
  line-height: 1.4;

  h3 {
    color: $primary;
    font-weight: $font-weight-regular;
    font-size: 0.75rem;
    text-transform: uppercase;
    background-color: #fff;
    width: fit-content;
    padding: 0.5rem;
  }

  h4 {
    font-size: 2rem;
    font-weight: $font-weight-extrabold;
    color: $primary;
    padding-bottom: 1em;
    padding: 1rem 0;
  }

  h2 {
    text-align: left !important;
    width: 100%;
    text-transform: uppercase;
    font-size: clamp(#{toRem(26)}, 2vw, #{toRem(30)});
    font-weight: bolder !important;
    margin: toRem(12) 0;
    padding: 10% 0 0 !important;

    @include maxWidth {
      width: 70%;
    }
  }

  .slideDescription {
    font-size: 1rem;
    font-weight: $font-weight-regular;
    font-style: normal;
    letter-spacing: 0.6px;
  }
}

.active {
  span {
    width: 28px !important;
  }
}
