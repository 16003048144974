@import '@styles/theme', '@styles/mixins';

.container {
  position: relative;
}

.nextTab {
  margin-left: 16px;
}

.slideImageContainer {
  aspect-ratio: 375 / 269 !important;
  @include desktop {
    aspect-ratio: 61 / 39 !important;
  }
  picture,
  img {
    width: 100% !important;
    height: 100% !important;
  }
}

.controls {
  position: absolute;
  top: clamp(200px, 50vw, 400px);
  margin-top: toRem(20);
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  .bullet {
    // width: 30px;
    height: 30px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.active {
      span {
        background-color: $input-box-primary;
        width: 28px !important;
      }
    }
    span {
      transition: all $transition-time-quick $transition-easing;
      display: block;
      background-color: $text-secondary;
      width: 8px !important;
      height: 8px !important;
    }
  }
}

.videoWrapper {
  min-height: 26vw;
  width: 100%;
  position: relative;

  @include maxWidth {
    max-height: 560px;
    min-height: unset;
    height: 26vw;
  }

  iframe {
    width: 100vw;
    height: clamp(200px, 50vw, 400px);
    @include desktop {
      height: 56.25vw;
    }
    object-fit: contain;
  }
}

.slideImageContainer {
  height: clamp(200px, 50vw, 400px) !important;

  img {
    height: clamp(200px, 50vw, 400px) !important;
  }
}

.accordionTopic {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0 1rem;
  width: calc(
    100% - #{$section-horizontal-padding} - #{$section-horizontal-padding}
  );
}

.slideContainer {
  background-color: #340f1c;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.slideDetails {
  min-width: 350px;
  flex-shrink: 0;
  line-height: 1.4;
  padding: 0 1rem;
  padding-bottom: 2rem;
  background-color: transparent;
  color: $text-primary;
  min-height: 350px;
  padding-top: toRem(70);

  h3 {
    color: $text-secondary;
    font-weight: 500;
    font-size: toRem(18);
  }

  h2 {
    text-align: left !important;
    width: 100%;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    margin: toRem(12) 0;
    padding: 0 !important;
    color: $primary;

    @include maxWidth {
      width: 70%;
    }
  }

  .slideDescription {
    font-size: 1rem;
    font-weight: $font-weight-regular;
    line-height: 1.2rem;
    color: $text-primary;
    font-family: $font;
  }
}

.titleAccordion {
  text-transform: capitalize;
}
