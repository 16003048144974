@import '@styles/theme', '@styles/mixins';

.container {
  padding-top: 0 !important;
}

.pageHeading {
  position: absolute;
  width: 100%;
  z-index: -1;
}

.firstSlide {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  border: 1px solid green;

  img {
    width: calc(100% - 110px);
    max-width: 333px;
    margin: calc(50px + 10%) auto auto 60px;

    @include sm {
      margin: calc(20px + 10%) auto 0 15%;
    }
  }
}

.slides {
  display: flex;
  width: 100%;
  height: 50%;
  flex-direction: column;

  h3 {
    display: block !important;
    font-weight: 500;
    text-shadow: 2px 2px #{$text-secondary};

    strong {
      display: inline !important;
      font-weight: 700;
    }
  }

  .content {
    justify-content: flex-start;
    align-items: flex-start;
    margin: calc(8.5% + 50px) 0 0 10%;
    width: max-content;
    height: fit-content;
  }

  .descriptionContent {
    margin-top: #{toRem(24)};
    font-size: 18px;
    max-width: calc(100vw - 80px);
  }

  .slidesContainer {
    width: 100%;
    height: 100%;

    .topBox {
      height: 40%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .wrapLogo {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 40px;

        .logo {
          max-width: calc(100% - 158px);
          margin: 0 auto;
          aspect-ratio: 2.65;
          position: relative;
          width: 100%;
          height: 200px;

          @media (max-width: 1024px) {
            margin-left: 2rem;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .svgContainer {
          width: 58px;
          height: 75px;
          margin-right: 25px;
          flex-shrink: 0;
        }
      }
    }

    .bottomBox {
      height: 78%;
      width: 100%;
      display: inherit;
      justify-content: flex-start;
      flex-direction: column;
      padding: 20% 5% 0;

      h2 {
        font-size: 2rem;
        display: inherit;
        margin-bottom: 1rem;

        em {
          color: $primary;
        }

        small {
          // position: absolute;
          top: 5%;
          font-size: 1rem;
        }
      }

      p {
        width: 100%;
        font-size: 1.125rem;
      }

      div {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: flex-start;
        justify-content: center;
      }

      &.haveSmall {
        padding: 9% 5% 0;
      }
    }
  }
}