@import "@styles/theme", "@styles/mixins", "@styles/animations";

.container {
  background: rgb(0, 0, 0);
  min-height: $min-mobile-height;

  @include desktop {
    min-height: unset;
    // padding-top: toRem(75) !important;
    // padding-bottom: toRem(25) !important;
  }
}

.swiper-slide {
  overflow: initial !important;
}

.controlsDesktop {
  button {
    position: absolute;
    // z-index: 1003;

    &.arrowRight {
      right: 30px;
      top: calc(50% - 18px);
    }

    &.arrowLeft {
      left: 30px;
      top: calc(50% - 18px);
    }
  }
}

.expandIcon {
  position: absolute;
  z-index: 2;
  margin: 1rem;
  right: 0;
  top: 0;
}
.controls {
  position: relative;
  margin-top: toRem(26);
  margin-bottom: toRem(36);
  z-index: 3;
  width: 100%;
  @include desktop {
    width: 30%;
  }
  display: flex;
  justify-content: space-between;

  .arrowButton {
    background-color: transparent;
  }

  .bullet {
    height: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    span {
      transition: all $transition-time-quick $transition-easing;
      display: block;
      background-color: $input-box-secondary;
      width: 8px;
      height: 8px;
    }

    &.active {
      @include bulletActive($text-brand-primary);
      span {
        width: 24px;
      }
    }
  }

  @include desktop {
    margin-bottom: 0;
    flex-direction: column;
    position: absolute;
    bottom: 0;

    .arrowBox {
      display: flex;
      gap: 1.4rem;
      padding-left: 6rem;
      // padding-top: 1rem;
    }
    .arrowButton {
      background-color: $primary;
      transition: ease 0.3s;

      &:hover {
        background-color: $background-secondary;
      }
    }

    .bullets {
      display: inline-flex;

      justify-content: flex-start;
      padding-left: 6rem;
      padding-bottom: 1rem;

      span {
        transition: all $transition-time-quick $transition-easing;
        display: block;
        background-color: $input-box-secondary;
        width: 8px;
        height: 8px;

        &:hover {
          background-color: $primary;
        }
      }

      &.active {
        @include bulletActive($primary);
        span {
          width: 24px;
        }
      }
    }
  }
}

.mainImage {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 60vw;
  max-height: 400px;
  position: relative;
  margin-bottom: 22px;

  div,
  img,
  picture {
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
  }

  @include desktop {
    margin: 0;
    max-width: 700px;
  }
}

.connectMeContainer {
  margin-top: toRem(26);
  text-align: center;
  z-index: 2;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    display: inline;
    font-weight: $font-weight-regular;
    margin-bottom: 1.6rem;
    z-index: 4;

    em {
      font-style: normal;
      font-weight: $font-weight-extrabold;
    }
  }

  @include desktop {
    margin: 0;
    h2 {
      margin: 0;
      margin-bottom: 4rem;
      font-size: 2rem;
    }
  }
}

.aligner {
  padding-top: 1.6rem;
  @include desktop {
    padding: 0;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    flex-direction: row;
  }
}
x .swiperMobile {
  padding: 0.5rem 0 0;
}

.thumbs {
  .active {
    margin: 0;
    padding: 0;
    img {
      border: 4px solid $input-box-primary;
      //padding-bottom: 6px;
    }
  }

  .bar {
    width: 100%;
    height: 4px;
    background-color: $primary;
  }

  @include desktop {
    padding-top: 2rem;
  }
}

.description {
  width: 100%;
  flex-direction: column;
  display: flex;
  // justify-content: flex-start;
  align-items: flex-start;
  padding: 0 1rem;
  height: 15rem;

  h2 {
    font-size: 2.375rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    color: $primary;
    text-align: start;
  }

  p {
    font-size: 1rem;
    font-weight: $font-weight-book;
    text-align: start;
    color: white;
    line-height: 1.5rem;
  }

  @include desktop {
    padding: 0;
    padding-top: 1rem;
    padding-left: 6rem;
    h2 {
      margin: 0;
      margin-bottom: 0.9rem;
      &::after {
        content: "";
        height: 4px;
        width: 50px;
        position: absolute;
        top: 0rem;
        left: 6rem;
        border-top: 3px solid #fff;
      }
    }
  }
}

.backgroundImage {
  position: absolute;
  width: 100%;
  z-index: 0;
  top: 0;
  filter: brightness(20%);
  overflow: hidden;
  height: 100px;

  @include desktop {
    height: 100%;
    // width: 100%;

    object-fit: cover;
  }
}

.connectMeLogoDesktop {
  position: relative;
  display: flex;
  //  padding-left: 6rem;
  left: 6rem;
}

.youtubeEmbed {
  position: absolute;
  width: 100%;
  height: 0;
  display: block;
  padding-bottom: 56.25%; /* Proporção de aspecto 16:9 */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: none !important;
    width: 100%;
    height: 100%;
  }

  iframe::-webkit-media-controls-start-playback-button,
  iframe::-webkit-media-controls-play-button,
  iframe::-webkit-media-controls-timeline,
  iframe::-webkit-media-controls-current-time-display {
    display: block !important;
    z-index: 9999999999 !important;
    background-color: red !important;
    width: 100%;
    bottom: 0;
  }

  iframe::-webkit-media-controls-play-button {
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    &:before {
      content: "\25B6"; /* Símbolo de reprodução */
      font-size: 32px;
      line-height: 50px;
      color: white;
    }
  }
}
