@import "@styles/theme", "@styles/mixins";

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: toRem(28);
  background-color: #1c1c1c;
  min-height: 200px;
  background-color: #340f1c;

  @include desktop {
    flex-direction: column;
  }
}

.socialMedia {
  display: flex;
  flex-direction: column;
  padding-top: toRem(20);
  text-align: center;

  @include desktop {
    text-align: left;
    margin-left: 2rem;

    p {
      margin-left: 1.2rem;
    }
  }
}

.footerIcon {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include desktop {
    margin-right: 2rem;
    flex-direction: row;
    align-items: flex-start;
  }
}

.footerIbamaMobile {
  min-height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 27%;
  @include desktop {
    width: 11%;
  }
}

.conditional,
.buttonsHolder {
  width: 100%;
}

.buttonsHolder {
  display: flex;
  flex-direction: column;
  padding: 0 toRem(26);

  .menuButton {
    margin-bottom: toRem(8);
    width: 100%;
    line-height: toRem(34);

    &:after {
      z-index: 0 !important;
    }

    p {
      font-size: toRem(18);
    }
  }
}

.social {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;

  @include desktop {
    padding-left: 6rem;
    padding-right: 11rem;
    width: 100%;
    justify-content: space-between;
  }

  .footerSocialMedia {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: toRem(20);
    width: 100%;
  }

  a {
    position: relative;
    display: block;
    overflow: hidden;
    text-indent: -2000px;
    width: 6rem;
    height: toRem(56);
    color: $text-primary;
    text-align: center;

    @include desktop {
      color: $text-primary;
      padding-top: toRem(45);
      height: toRem(45);
    }

    &:not(:last-child) {
      margin-right: 0;
    }

    &:hover,
    &:active {
      color: $text-primary;

      @include desktop {
        color: $primary;
      }
    }

    &:before {
      font-family: $fontawesome-brands;
      font-size: toRem(28);
      content: attr(data-before);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: toRem(-9);
      margin-top: toRem(-12);
      display: block;
      text-indent: 0;
    }
  }
}

.footerText {
  padding: 0.5rem 4rem;
  margin-bottom: 3rem;

  p {
    text-align: center;
    font-size: 1rem;
    font-weight: $font-weight-regular;
  }

  @include desktop {
    padding: 0 1rem;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 35rem;
    padding-bottom: 4rem;

    p {
      font-size: 0.8rem;
    }
  }
}

.mobileBrandContainer {
  margin-bottom: toRem(50);

  img {
    width: 100%;
  }
}

.brandFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.ctas {
  padding: 0 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 5rem;

  .ctaButton {
    div {
      height: 46px;
      p {
        font-size: 0.9rem;
        font-weight: 700;
        font-style: italic;
      }
    }
  }

  @include desktop {
    flex-direction: row;
    padding: 2rem 4rem;
    // padding-top: 2rem;
  }
}

.letsDeal {
  display: inline;
  width: 100%;
  text-align: center;
  h2{
    display: inline;
    font-weight: $font-weight-regular;
    font-size: 2.5rem;
  }

  em{
    font-weight: $font-weight-extrabold;
    font-style: normal;
  }


}
.legalText{
margin-top: 1rem;
  opacity: 0.8;
  font-size: 0.5rem;
}