@import "@styles/theme", "@styles/mixins";

.container {
  width: 100%;
  margin: 0 auto;

  .photoContent {
    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
      flex-direction: row;
      gap: 12px;
    }
  }

  .box {
    background-size: cover;
    height: 100vh;

    &.red {
      position: relative;
      background: url("../../../public/images/Design/mobile/familia-de-picapes-Toro-mobile@3x.webp")
        center no-repeat;
      background-size: cover;
      .content {
        width: 100%;
        height: auto;
        margin-right: 8.5%;
        @media (max-width: 1024px) {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          padding: 24px 16px;
          margin-right: 0;
        }

        .title {
          color: #fff;
          text-transform: uppercase;
          line-height: 1;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            background-color: #fff;
            bottom: -10px;
            z-index: 1000;
            width: 80px;
            height: 4px;
          }
          @media (max-width: 1024px) {
            font-size: 32px;
          }

          strong {
            color: #cbad9b;
            margin-bottom: 6px;
            white-space: nowrap;
          }
        }

        .contentTitle {
          width: fit-content;
        }

        .contentArea {
          display: flex;

          @media (max-width: 1024px) {
            flex-direction: column;
          }

          .numberAndDescription {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: 1024px) {
              align-items: flex-start;
            }

            .titleText {
              // background-color: #f0e8e2;
              color: #342118;
              padding: 1px 12px;
              background-color: #fff;
              transform: skewX(-10deg);

              font-style: italic;
              font-weight: 100;
              font-size: 18px;
            }
          }

          h2 {
            font-size: 50px;
          }

          h3 {
            font-size: 18px;
          }

          ul {
            padding: 10px;

            @media (max-width: 1024px) {
              padding: 0;
            }
          }

          ul > li {
            list-style: none;
            margin-bottom: 12px;
            text-align: center;
          }

          @media (max-width: 1024px) {
            ul > li {
              text-align: left;
              margin-bottom: 5px;
            }
          }
        }

        p {
          font-size: 1rem;
          color: #f0e8e2;
          margin-top: 25px;
          margin-bottom: 30px;

          line-height: 22px;
          font-style: normal;
          font-weight: 350;
        }
      }
    }
  }

  @include desktop {
    .box {
      max-height: 518px;

      @media (min-width: 1500px) {
        max-height: 618px;
      }
      @media (min-width: 1920px) {
        max-height: 690px;
      }
      background-size: contain;
      &.red {
        position: relative;

        background: url("../../../public/images/Design/desktop/familia-de-picapes-toro@3x.webp")
          center no-repeat;
        background-size: cover;
        .content {
          width: 433px;
          position: absolute;
          top: 77px;
          left: 76px;
          //transform: translateY(-50%);
          height: auto;

          .contentArea {
            display: grid;
            grid-template-columns: 1fr 0.1fr 1fr 0.1fr 1fr;
            gap: 20px;
            width: 100%;
          }

          .title {
            font-size: 2rem;
            color: #fff;

            strong {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.universal_cta {
  color: #fff;
  height: 40px;
  background-color: #ff1331;
  padding: 10px 16px;
  border-radius: 2px;
  width: 100%;

  @include desktop {
    width: 368px;
  }
}
