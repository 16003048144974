@import "@styles/theme", "@styles/mixins";

.container {
  background: rgb(50,15,28);
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(50,15,28,1) 100%);
  h2 {
    padding-top: toRem(28);
    text-align: center;
    padding-bottom: toRem(32);
    font-size: clamp(#{toRem(28)}, 2.6vw, #{toRem(32)});
    font-weight: $font-weight-regular;
    @include desktop {
      padding-top: toRem(48);
      padding-bottom: toRem(48);
    }
  }
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: toRem(0) !important;
  padding-bottom: toRem(30) !important;
  border-top: none;

  @include desktop {
    border-top: 1px solid transparent;
    margin: 0 !important;
  }
}

.sectionName {
  font-size: 2rem;
  font-weight: $font-weight-regular;
  display: block;
  width: 100%;
  text-align: center;
  padding: 2rem 0;


  em {
    font-style: normal;
    font-weight: $font-weight-extrabold;
  }

  @include desktop{
    margin-bottom: 2.5rem;
  }
}
