@import '@styles/theme', '@styles/mixins';

.pageHeading {
  position: absolute;
  width: 100%;
  z-index: -1;
}

.container {
  width: 100%;
  height: 100vh;
  min-height: 500px !important;
  position: relative;
  display: flex;
  flex-direction: column;
  h1 {
    display: none;
  }

  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.slides {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .fullBgGradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(#00000047, transparent);
  }

  &.centered {
    align-items: center;
    justify-content: center;

    // .firstContent {
    //   margin: -21% auto 0 12%;
    //   width: 50%;
    //   max-width: 18%;
    //   height: fit-content;
    //   justify-content: flex-start;
    // }
  }

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    margin: 0 auto;
    width: 100%;
    height: 100%;

    .wrapLogo {
      width: 100%;
      min-height: 71px;
      margin-top: 6%;
      display: flex;
      justify-content: center;
      .boxLogo {
        width: 55%;
        height: 70%;
        margin-left: -4%;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .descriptionContent {
      width: 100%;
      max-width: 600px;
      height: 110px;
      pointer-events: none;

      .oldDescriptionLayoutOne {
        position: absolute;
        bottom: unset;
        margin: 0 !important;
        padding: 0;
        left: clamp(20px, 6vw, 90px);
        top: 20%;

        @media (min-width: 1400px) {
        }

        @media (min-width: 1600px) {
          left: clamp(20px, 13vw, 130px);
        }

        @include xxl {
          left: clamp(40px, 13vw, 180px);
        }

        p {
          margin-top: 0;
          font-size: 2.5rem;
          line-height: 2.5rem;
          text-transform: uppercase;
          font-weight: 600;
          margin-bottom: 2%;
          color: $text-light;
        }
        img {
          height: 2rem;
        }
        img {
          position: relative;
          left: 0;
          top: 0;
          width: 60%;
        }
      }

      .descriptionLayoutOne {
        position: absolute;
      }

      img {
        position: relative;
        width: 167%;
        left: -4.6rem;
        bottom: 3.8rem;
      }

      /* -----------------> 1366 x 768 <----------------- */
      //@media (min-width: 1350px) {
      //  img {

      //  }
      //}

      @media (min-width: 1364px) {
        img {
          position: relative;
          width: 167%;
          left: -4.8rem;
          bottom: 3.8rem;
        }
      }

      /* -----------------> 1440 x 990 <----------------- */
      @media (min-width: 1423px) {
        img {
          position: relative;
          width: 186%;
          left: -7.6rem;
          bottom: 4.3rem;
        }
      }
      @media (min-width: 1438px) {
        img {
          position: relative;
          width: 176%;
          left: -6rem;
          bottom: 4.2rem;
        }
      }

      @media (min-width: 1535px) {
        img {
          position: relative;
          width: 186%;
          left: -7.6rem;
          bottom: 4.5rem;
        }
      }

      /* -----------------> 1680 x 1050 <----------------- */
      @media (min-width: 1662px) {
        img {
          position: relative;
          width: 217%;
          left: -8.6rem;
          bottom: 5.3rem;
        }
      }
      @media (min-width: 1678px) {
        img {
          position: relative;
          width: 216%;
          left: -8.2rem;
          bottom: 5.1rem;
        }
      }

      /* -----------------> 1920 x 1080 <----------------- */
      @media (min-width: 1904px) {
        img {
          position: relative;
          width: 216%;
          left: -4.6rem;
          bottom: 5.2rem;
        }
      }

      @media (min-width: 1917px) {
        img {
          position: relative;
          width: 221%;
          left: -5.1rem;
          bottom: 5.3rem;
        }
      }

      /* -----------------> 2560 x 1440 <----------------- */
      @media (min-width: 2544px) {
        img {
          position: relative;
          width: 296%;
          left: -20.2rem;
          bottom: 15.9rem;
        }
      }

      @media (min-width: 2557px) {
        img {
          position: relative;
          width: 298%;
          left: -20.5rem;
          bottom: 16.2rem;
        }
      }

      @media (min-width: 1600px) {
        left: clamp(20px, 13vw, 130px);
      }

      @include xxl {
        left: clamp(40px, 13vw, 223px);
      }
    }
    .descriptionLayoutTwo {
      width: inherit;
      position: absolute;
      bottom: unset;
      margin: 0 !important;
      padding: 0;
      left: clamp(20px, 15vw, 225px);
      top: 15%;
      height: 100%;

      .tltBottom {
        font-size: 3.75rem;
        text-transform: uppercase;
        line-height: 3.6rem;
        margin-bottom: 2rem;
        color: $primary;
        display: inherit;
        em {
          color: $text-light;
        }
        small {
          color: $text-light;
          top: 5%;
          font-size: 1.5rem;
          line-height: 1rem;
        }
      }
      p {
        position: relative;
        max-width: 100%;
        font-size: 1rem;
        font-weight: $font-weight-regular;
        padding-left: 2rem;
        z-index: 3;
      }
      img {
        width: 20%;
      }
    }
  }
}

.logoContent {
  height: 11vh;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;

  .logoHero {
    margin-top: 15px;
  }
}


.heroContent {
  position: absolute;
  top: 7rem;
  left: 10rem;

  display: flex;
  flex-direction: column;
  gap: 3vh;


}

.heroTitle {
  width: 20vw;
}

.svgContainer {
  width: 6vw;
  height: fit-content;
}

.descriptionBox {
  position: absolute;
  top: 40%;
}

.descriptionOrnament {
  position: absolute;
  left: -30px;
  top: -50px;
  z-index: 0;
}
