@import "@styles/theme", "@styles/mixins", "@styles/animations";

.container {
@include desktop {
  padding: 0 25px;
}
}

.versions {
  padding: 50px 0 30px;
  background: rgb(214, 214, 191);
  background: linear-gradient(
    180deg,
    rgba(214, 214, 191, 1) 70%,
    rgba(49, 15, 27, 0.8) 110%
  );

  .btnPrev,
  .btnNext {
    display: none;
  }

  .names {
    display: flex;
    justify-content: space-around;
    margin: 29px 0 10px;
    list-style: none;
    position: relative;
    z-index: 1;

    .btns {
      font-size: 1rem;
      font-weight: 900;
      padding: 0 22px 5px;
      display: flex;
      flex-direction: row;


      @include mobile {
        justify-self: center;
      }

      p {
        color: $terciary;
        text-decoration: none;
        font-weight: 400;
        font-size: 1.25rem;
      }
      div {
        background-color: $terciary;
        padding: 0px 20px;
        font-family: $font;
        font-weight: $font-weight-regular;

        clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
        overflow: visible;

        em {
          color: #e9e9dd;
          font-family: $font;
          font-weight: $font-weight-extrabold;
          text-transform: uppercase;
          font-size: 1.25rem;
          white-space: nowrap;
        }
      }

      &.active {
        p {
          color: $primary;
        }
        div {
          background-color: $primary;
          height: 35px;

          em {
            color: $text-light;
          }

          &::after {
            position: absolute;
            bottom: 8px;
            width: 110%;
            left: 0;
            height: 3px;
            content: "";
            z-index: 1;
            background-color: #d5d6bf;
          }
        }
      }
    }

    @include desktop {
      gap: 0px;
    }
  }

  .carousel {
    width: 100%;
    text-align: center;

    .images {
      display: flex;
      width: 50%;
      object-fit: contain;
    }

    @include desktop {
      display: flex;
      justify-content: center;
      align-items: center;
      .mainImage {
        width: 700px;
        height: 500px;
      }
    }
  }
}

.head {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    display: inline-block;
    text-align: center;
    font-weight: $font-weight-regular;
    font-size: 1.5rem;
    color: $text-secondary;

    em {
      font-weight: $font-weight-extrabold;
      color: $primary;
      font-style: normal;
    }
  }
}


.previous {
  position: absolute;
  left: 30px;
  top: 50%;
  margin-top: -20px;
  z-index: 4;
}

.next {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -20px;
  z-index: 4;
}