@import "@styles/theme", "@styles/mixins";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 #{$section-horizontal-padding} #{toRem(52)};

  @include desktop {
    padding: 0 3rem;
  }

  .tab {
    text-decoration: none;
    position: relative;
    width: 100%;
    flex-grow: 1;

    &.active {
      .border {
        background: #b0a477;
      }
    }

    .border {
      width: 100%;
      height: 2px;
      display: flex;
      content: "";
      transition: all 200ms ease-in-out;
      background: #2e2e2e;
    }

    .content {
      position: relative;
      z-index: 2;
      line-height: toRem(32);
      color: $text-primary;
      padding: 0 toRem(10);
      transition: all $transition-time-quick $transition-easing;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      background-color: transparent;
      h3 {
        font-size: clamp(0.9rem, 1vw, 1rem);
        font-weight: normal;
        color: $text-primary;
      }
    }

    &.active {
      background-color: #fff;
      h3 {
        color: $primary;
      }
      p {
        font-weight: bold;
      }

      &::after {
        content: "";
        width: 100%;
        height: 4px;
        position: absolute;
        bottom: -0.5rem;
        background-color: #fff;
      }
    }

    &:not(.active) {
      &::after {
        content: "";
        height: 4px;
        width: 80%;
        background-color: $primary;
        position: absolute;
        bottom: 0;
        transform: translateX(12%)
      }
    }
  }
}
