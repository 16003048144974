@import '@styles/theme', '@styles/mixins', '@styles/animations';

.container {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 11%,
    rgba(37, 11, 20, 1) 75%
  );
  min-height: $min-mobile-height;

  @include desktop {
    min-height: 700px;
    padding-top: toRem(75) !important;
    padding-bottom: toRem(25) !important;
  }
}

.swiper-slide {
  overflow: initial !important;
}

.expandedGallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-dark;
  z-index: 1001;

  @include desktop {
    background-color: rgba($background-dark, 0.9);
  }

  .closeButton {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1rem;
    z-index: 100;
    color: $text-primary;
  }

  .controls {
    margin: 26px 0 0 !important;
    padding: 0 #{$section-horizontal-padding} !important;
    max-width: 100vw;
  }

  .swiperMobile {
    overflow: initial !important;
    padding: 0 !important;
  }
}

.controlsDesktop {
  button {
    position: absolute;
    z-index: 1003;

    &.arrowRight {
      right: 30px;
      top: calc(50% - 18px);
    }

    &.arrowLeft {
      left: 30px;
      top: calc(50% - 18px);
    }
  }
}

.expandIcon {
  position: absolute;
  z-index: 2;
  margin: 1rem;
  right: 0;
  top: 0;
}

.videoHolder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}

.controls {
  position: relative;
  margin-top: toRem(26);
  margin-bottom: toRem(36);
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 #{$section-horizontal-padding};

  .arrowButton {
    background-color: transparent;
  }

  .bullet {
    height: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include desktop {
      &:hover {
        @include bulletActive($terciary);
      }
    }

    span {
      transition: all $transition-time-quick $transition-easing;
      display: block;
      background-color: $input-box-secondary;
      width: 8px;
      height: 8px;
    }

    &.active {
      @include bulletActive($text-brand-primary);
      span {
        width: 24px;
      }
    }
  }
}

.mainImage {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 60vw;
  max-height: 400px;
  position: relative;
  margin-bottom: 22px;

  @include desktop {
    height: 40vw;
    min-height: 400px;
    max-width: unset;
    max-height: 600px;
    margin-right: toRem(16);
  }

  div,
  img,
  picture {
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.galleryContainer {
  margin-top: toRem(26);
  text-align: center;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    display: inline;
    font-weight: $font-weight-regular;
    margin-bottom: 1.6rem;

    em {
      font-style: normal;
      font-weight: $font-weight-extrabold;
    }
    @include desktop {
      margin-bottom: 4rem;
      font-size: 2rem;
    }
  }
}

.aligner {
  padding-top: 1.6rem;
  @include desktop {
    padding: 0 $section-horizontal-padding-desktop;
    padding-top: 36px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .thumbsDesktop {
    flex-grow: 1;
    width: 40%;
    min-width: 300px;
    max-height: 600px;
    overflow-y: auto;
    position: relative;

    button {
      opacity: 0.6;
      transition: opacity $transition-time-quick $transition-easing;

      &:hover {
        filter: brightness(50%);
      }

      &.active {
        opacity: 1;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 5px;
          background: $input-box-primary;
          left: 0;
          bottom: 3px;
          position: relative;
        }
      }

      &:nth-child(odd) {
        margin-right: toRem(14);
      }

      width: calc(50% - 0.5rem);
      margin-bottom: toRem(8);

      img {
        width: 100%;
        max-width: unset !important;
      }
    }
  }
}
x .swiperMobile {
  padding: 0.5rem 0 0;
}

.thumbs {
  .active {
    margin: 0;
    padding: 0;
    border: 4px solid $input-box-primary;
  }

  .bar {
    width: 100%;
    height: 4px;
    background-color: $primary;
  }
}

.expandedSlide {
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;

  @include desktop {
    height: 100vh;
    padding: calc(#{$section-horizontal-padding-desktop} / 2) #{$section-horizontal-padding-desktop};
    img {
      max-height: calc(100vh - #{$section-horizontal-padding-desktop} / 2);
    }
  }
}
